import dayjs from "dayjs"
import { ImageView } from "./common"
import { clStores } from "~/clStores"
import { MessageOGP } from "~/components/custom/chat/local/PMessageList.vue"

export interface ChatGroupView {
  id: string
  corporationId: string
  corporationLogoPath: string | null
  corporationLogoImage: ImageView | null
  corporationName: string
  universalCorporationId: string
  universalUid: string
  userName: string
  userProfileId: string
  userProfileImagePath: string | null
  userProfileImage: ImageView | null
  lastMessageDate: string
  latestMessageText: string | null
  latestFileName: string | null
  latestFilePath: string | null
  isRead: boolean
}

export const decorateChatGroupView = (view: ChatGroupView) => ({
  formatLastMessageDateForList: (): string =>
    dayjs(new Date(view.lastMessageDate)).format("MM/DD"),
})

export interface ChatGroupListView {
  list: ChatGroupView[]
  numberOfTotalPages: number
  numberOfTotalHits: number
}

export interface NumberOfProjectApplicationsForProjectListView {
  list: NumberOfProjectApplicationsForProjectView[]
}

export interface NumberOfProjectApplicationsForProjectView {
  projectId: string
  numberOfProjectApplications: number
}

export interface NumberOfScoutsView {
  universalCorporationId: string
  numberOfScouts: number
}

export interface NumberOfProjectApplicationsForCorporationView {
  universalCorporationId: string
  numberOfProjectApplications: number
}

export interface MessageView {
  id: string
  type?: "report_matching"
  senderType: "bot" | "corporation" | "user" | "system"
  chatGroupId: string
  corporationLogoImage: ImageView | null
  corporationName: string | null
  universalCorporationId: string | null
  universalUid: string | null
  userName: string | null
  userProfileImage: ImageView | null
  text: string | null
  fileName: string | null
  filePath: string | null
  postedAt: string
  isCanceled: boolean
}

export const decorateMessageView = (view: MessageView) => ({
  formatLastMessageDateForGroup: (): string =>
    dayjs(new Date(view.postedAt)).format("HH:mm"),
  extractURLList(): string[] {
    const urlPattern = /(https?:\/\/[\x21-\x7E]+)/g
    const urlList = view.text ? view.text.match(urlPattern) : null
    return urlList ? urlList.map((elm) => elm.toString()) : []
  },
  getMessageOGPs(): MessageOGP[] {
    const urlList = decorateMessageView(view).extractURLList()
    return urlList
      .map((url) => {
        if (!clStores.ogp.getters.getOGPMetadata(url)) {
          return undefined
        }

        return {
          siteURL: url,
          ...clStores.ogp.getters.getOGPMetadata(url),
        }
      })
      .filter((elm): elm is MessageOGP => !!elm) // filterだけではTypeScriptがnullableと判定してしまうので、致し方なくisで型を補強している。
  },
})

export interface MessageListView {
  list: MessageView[]
}

export const decorateMessageListView = (views: MessageView[]) => ({
  getMessageOGPs(): MessageOGP[] {
    return views
      .map((message) => decorateMessageView(message).getMessageOGPs())
      .flat()
  },
  loadMessageOGPs() {
    views.forEach((message) => {
      const urlList = decorateMessageView(message).extractURLList()

      urlList.forEach((url) => {
        clStores.ogp.actions.load(url)
      })
    })
  },
})

export interface FileMessageView {
  fileName: string
  url: string
}

export interface NumberOfUnreadMessagesView {
  number: number
}

export type SelectionStatus = "during_selection" | "end_of_selection"

export type ProjectApplicationSortType =
  | "project_application_created"
  | "latest_message_sent"

export interface ProjectApplicationDetailListView {
  numberOfTotalPages: number
  numberOfTotalHits: number
  list: ProjectApplicationDetailView[]
}

export interface ProjectApplicationDetailView {
  id: string
  selectionId: string
  pending: boolean
  projectId: string
  projectTitle: string
  chatGroupId: string
  notRepliedMessage: boolean
  unreadMessage: boolean
  universalUid: string
  userProfileImage?: ImageView
  userFullName: string
  userMainSmallJobCategoryId: number | null
  userMainSmallJobCategoryName: string | null
  userSubSmallJobCategoryId: number | null
  userSubSmallJobCategoryName: string | null
  selectionStatus: SelectionStatus
  latestMessageSentDateTime: string | null
  appliedDateTime: string
}

export const decorateProjectApplicationDetailView = (
  view: ProjectApplicationDetailView
) => ({
  formatLatestMessageSentDateForList: (): string =>
    dayjs(new Date(view.latestMessageSentDateTime || "")).format("MM/DD"),
  formatAppliedDateForList: (): string =>
    dayjs(new Date(view.appliedDateTime)).format("MM/DD"),
})

export interface ScoutDetailListView {
  numberOfTotalPages: number
  numberOfTotalHits: number
  list: ScoutDetailView[]
}

export interface ScoutDetailView {
  id: string
  selectionId: string
  chatGroupId: string
  notRepliedMessage: boolean
  unreadMessage: boolean
  userResponseStatus: UserResponseStatus
  pending: boolean
  universalUid: string
  userProfileImage?: ImageView
  userFullName: string
  userMainSmallJobCategoryId: number | null
  userMainSmallJobCategoryName: string | null
  userSubSmallJobCategoryId: number | null
  userSubSmallJobCategoryName: string | null
  scoutSenderName: string
  selectionStatus: SelectionStatus
  latestMessageSentDateTime: string | null
  scoutedDateTime: string
  targetProjectId: string
}

export const decorateScoutDetailView = (view: ScoutDetailView) => ({
  formatLatestMessageSentDateForList: (): string =>
    dayjs(new Date(view.latestMessageSentDateTime || "")).format("MM/DD"),
  formatScoutedDateForList: (): string =>
    dayjs(new Date(view.scoutedDateTime)).format("MM/DD"),
  formatLatestMessageSentYearDateTimeForList: (): string =>
    dayjs(new Date(view.latestMessageSentDateTime || "")).format(
      "YYYY/MM/DD HH:mm:ss"
    ),
  formatScoutedDateTimeForList: (): string =>
    dayjs(new Date(view.scoutedDateTime)).format("YYYY/MM/DD"),
})
export type ScoutSortType = "scout_created" | "latest_message_sent"

export type UserResponseStatus = "unread" | "read" | "replied"
