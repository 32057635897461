/*
 * 本サービスでは、Role-Based Access Control（RBAC）の考え方に沿って、ユーザーをロールごとに権限管理するよう実装している。
 * ここでは、各ロールごとに有している権限をそれぞれ定義していっている。
 */
import {
  UserAccountRoleList,
  CorporationAccountRoleList,
} from "~/config/acl/userRoleList"
import {
  UniversalReadAcl,
  UniversalWriteAcl,
  ParticularReadAcl,
  ParticularWriteAcl,
  Acl,
} from "~/config/acl/accessControlList"

interface AccessControlListOfRole {
  /*
   * ユーザーアカウント用のロール
   */
  [UserAccountRoleList.UNKNOWN_ACCOUNT_ROLE]: Acl[]
  [UserAccountRoleList.PRE_SIGN_UP_ACCOUNT_ROLE]: Acl[]
  [UserAccountRoleList.NO_ENOUGH_PROFILE_ACCOUNT_ROLE]: Acl[]
  [UserAccountRoleList.ENOUGH_PROFILE_ACCOUNT_ROLE]: Acl[]

  /*
   * 企業アカウント用のロール
   */
  [CorporationAccountRoleList.FREE_ACCOUNT_ROLE]: Acl[]
  [CorporationAccountRoleList.TRIAL_ACCOUNT_ROLE]: Acl[]
  [CorporationAccountRoleList.PAID_ACCOUNT_ROLE]: Acl[]
}

export const accessControlListOfRole: AccessControlListOfRole = {
  /*
   * -------------------------
   *
   * UNKNOWN_ACCOUNT_ROLE
   * 未ログイン状態のユーザー用のロール
   */
  [UserAccountRoleList.UNKNOWN_ACCOUNT_ROLE]: [],
  /*
   * -------------------------
   */

  /*
   * -------------------------
   *
   * PRE_SIGN_UP_ACCOUNT_ROLE
   * ログイン済みの基本情報が登録されていないユーザーアカウント用のロール
   */
  [UserAccountRoleList.PRE_SIGN_UP_ACCOUNT_ROLE]: [
    /*
     * ParticularWriteAcl に関するリスト
     */
    ParticularWriteAcl.FIRST_BASE_PROFILE_REGISTRATION,
  ],
  /*
   * -------------------------
   */

  /*
   * -------------------------
   *
   * NO_ENOUGH_PROFILE_ACCOUNT_ROLE
   * ログイン済みのユーザーアカウント用のロール
   */
  [UserAccountRoleList.NO_ENOUGH_PROFILE_ACCOUNT_ROLE]: [
    /*
     * UniversalReadAcl に関するリスト
     */
    UniversalReadAcl.REQUIRE_AUTHENTICATION_RESOURCES,

    /*
     * UniversalWriteAcl に関するリスト
     */
    UniversalWriteAcl.AUTHENTICATED_USER_RESOURCES,
  ],
  /*
   * -------------------------
   */

  /*
   * -------------------------
   *
   * ENOUGH_PROFILE_ACCOUNT_ROLE
   * ログイン済みのユーザーアカウント用のロール
   */
  [UserAccountRoleList.ENOUGH_PROFILE_ACCOUNT_ROLE]: [
    /*
     * UniversalReadAcl に関するリスト
     */
    UniversalReadAcl.REQUIRE_AUTHENTICATION_RESOURCES,

    /*
     * UniversalWriteAcl に関するリスト
     */
    UniversalWriteAcl.AUTHENTICATED_USER_RESOURCES,

    /*
     * ParticularWriteAcl に関するリスト
     */
    ParticularWriteAcl.PROJECT_APPLY,
  ],
  /*
   * -------------------------
   */

  /*
   * -------------------------
   *
   * FREE_ACCOUNT_ROLE
   * 切り替え中の無料の企業アカウント用のロール
   */
  [CorporationAccountRoleList.FREE_ACCOUNT_ROLE]: [
    /*
     * UniversalReadAcl に関するリスト
     */
    UniversalReadAcl.AUTHENTICATED_CORPORATION_RESOURCES,

    /*
     * UniversalWriteAcl に関するリスト
     */
    UniversalWriteAcl.AUTHENTICATED_CORPORATION_RESOURCES,

    /*
     * ParticularWriteAcl に関するリスト
     */
    ParticularWriteAcl.CORPORATION_SUBSCRIPTION_REQUEST,
  ],
  /*
   * -------------------------
   */

  /*
   * -------------------------
   *
   * TRIAL_ACCOUNT_ROLE
   * 切り替え中のトライアルの企業アカウント用のロール
   */
  [CorporationAccountRoleList.TRIAL_ACCOUNT_ROLE]: [
    /*
     * UniversalReadAcl に関するリスト
     */
    UniversalReadAcl.AUTHENTICATED_CORPORATION_RESOURCES,

    /*
     * UniversalWriteAcl に関するリスト
     */
    UniversalWriteAcl.AUTHENTICATED_CORPORATION_RESOURCES,

    /*
     * ParticularReadAcl に関するリスト
     */
    ParticularReadAcl.FULL_PARAMETER_USER_PROFILE_SEARCH,
    ParticularReadAcl.PAID_PLAN_ALERT_DISMISS,

    /*
     * ParticularWriteAcl に関するリスト
     */
    ParticularWriteAcl.CORPORATION_SUBSCRIPTION_REQUEST,
    ParticularWriteAcl.SEND_MESSAGE_TO_USER_AS_CORPORATION_ACCOUNT,
    ParticularWriteAcl.USER_SCOUT,
    ParticularWriteAcl.PROJECT_CREATE,
  ],

  /*
   * -------------------------
   */
  /*
   * -------------------------
   *
   * PAID_ACCOUNT_ROLE
   * 切り替え中の有料の企業アカウント用のロール
   */
  [CorporationAccountRoleList.PAID_ACCOUNT_ROLE]: [
    /*
     * UniversalReadAcl に関するリスト
     */
    UniversalReadAcl.AUTHENTICATED_CORPORATION_RESOURCES,

    /*
     * UniversalWriteAcl に関するリスト
     */
    UniversalWriteAcl.AUTHENTICATED_CORPORATION_RESOURCES,

    /*
     * ParticularReadAcl に関するリスト
     */
    ParticularReadAcl.FULL_PARAMETER_USER_PROFILE_SEARCH,
    ParticularReadAcl.PAID_PLAN_ALERT_DISMISS,

    /*
     * ParticularWriteAcl に関するリスト
     */
    ParticularWriteAcl.CORPORATION_SUBSCRIPTION_REQUEST,
    ParticularWriteAcl.SEND_MESSAGE_TO_USER_AS_CORPORATION_ACCOUNT,
    ParticularWriteAcl.USER_SCOUT,
    ParticularWriteAcl.PROJECT_CREATE,
  ],
  /*
   * -------------------------
   */
}
