/*
 * 本サービスでは、Role-Based Access Control（RBAC）の考え方に沿って、ユーザーをロールごとに権限管理するよう実装している。
 * ここでは、各ロールごとに有している制限をそれぞれ定義していっている。
 */
import { CorporationAccountRoleList } from "~/config/acl/userRoleList"
import { Acl, ParticularWriteAcl } from "~/config/acl/accessControlList"

interface LimitOfRole {
  [CorporationAccountRoleList.FREE_ACCOUNT_ROLE]: LimitOfAcl[]
  [CorporationAccountRoleList.TRIAL_ACCOUNT_ROLE]: LimitOfAcl[]
  [CorporationAccountRoleList.PAID_ACCOUNT_ROLE]: LimitOfAcl[]
}
interface LimitOfAcl {
  acl: Acl
  limit: number | null
}

export const limitOfRole: LimitOfRole = {
  /**
   * 契約なしの企業アカウント
   * プロジェクト作成：3件
   * スカウト：50件
   */
  [CorporationAccountRoleList.FREE_ACCOUNT_ROLE]: [
    {
      acl: ParticularWriteAcl.USER_SCOUT,
      limit: 50,
    },
    {
      acl: ParticularWriteAcl.PROJECT_CREATE,
      limit: 3,
    },
  ],
  /**
   * トライアル契約の企業アカウント
   * プロジェクト作成：3件
   * スカウト：50件
   */
  [CorporationAccountRoleList.TRIAL_ACCOUNT_ROLE]: [
    {
      acl: ParticularWriteAcl.USER_SCOUT,
      limit: 50,
    },
    {
      acl: ParticularWriteAcl.PROJECT_CREATE,
      limit: 3,
    },
  ],
  /**
   * 有料契約の企業アカウント
   * 制限なし
   */
  [CorporationAccountRoleList.PAID_ACCOUNT_ROLE]: [],
}
