/**
 * read
 */
import { read } from "./read"
import { CountApplicationsForProjectReadRequest } from "./read/CountApplicationsForProjectReadRequest"
import { CountApplicationsForProjectsReadRequest } from "./read/CountApplicationsForProjectsReadRequest"
import { GetChatGroupByIdReadRequest } from "./read/GetChatGroupByIdReadRequest"
import { GetChatGroupByJoiningSetReadRequest } from "./read/GetChatGroupByJoiningSetReadRequest"
import { GetLatestMessageListReadRequest } from "./read/GetLatestMessageListReadRequest"
import { GetMyChatGroupListReadRequest } from "./read/GetMyChatGroupListReadRequest"
import { GetNumberOfUnreadMessagesOfScoutReadRequest } from "./read/GetNumberOfUnreadMessagesOfScoutReadRequest"
import { GetPastMessageListReadRequest } from "./read/GetPastMessageListReadRequest"
import { GetFileMessageByIdReadRequest } from "./read/GetFileMessageByIdReadRequest"
import { GetScoutByIdReadRequest } from "./read/GetScoutByIdReadRequest"
import { GetScoutDetailLatestReadRequest } from "./read/GetScoutDetailLatestReadRequest"
/**
 * write
 */
import { FinishSelectionWriteRequest } from "./write/FinishSelectionWriteRequest"
import { ScoutWriteRequest } from "./write/ScoutWriteRequest"
import { SendMessageWriteRequest } from "./write/SendMessageWriteRequest"
import { SendFileMessageWriteRequest } from "./write/SendFileMessageWriteRequest"
import { CancelFileMessageWriteRequest } from "./write/CancelFileMessageWriteRequest"

export const Matching = {
  /**
   * read
   */
  read,
  CountApplicationsForProjectReadRequest,
  CountApplicationsForProjectsReadRequest,
  GetChatGroupByIdReadRequest,
  GetChatGroupByJoiningSetReadRequest,
  GetLatestMessageListReadRequest,
  GetMyChatGroupListReadRequest,
  GetNumberOfUnreadMessagesOfScoutReadRequest,
  GetPastMessageListReadRequest,
  GetFileMessageByIdReadRequest,
  GetScoutByIdReadRequest,
  GetScoutDetailLatestReadRequest,
  /**
   * write
   */
  FinishSelectionWriteRequest,
  SendMessageWriteRequest,
  ScoutWriteRequest,
  SendFileMessageWriteRequest,
  CancelFileMessageWriteRequest,
}
