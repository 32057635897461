













import Vue from "vue"
import CDefaultApplication from "~/components/custom/layout/CDefaultApplication.vue"
import { makeAuthPagePath } from "~/uiLogics/auth/authExecutor"
import CLayoutTemplate from "~/components/custom/frame/CLayoutTemplate.vue"
import CAvailableUptimeUpdateModal from "~/components/custom/availableUptimeUpdateModal/CAvailableUptimeUpdateModal.vue"
import CInformationModal from "~/components/custom/modal/CInformationModal.vue"

export default Vue.extend({
  components: {
    CDefaultApplication,
    CLayoutTemplate,
    CAvailableUptimeUpdateModal,
    CInformationModal,
  },
  beforeMount() {
    if (this.$clAuth.tryingToLogin) {
      const path = makeAuthPagePath({
        redirectPath: this.$nuxt.$route.path,
        route: this.$route,
      })
      this.$nuxt.$router.push({ path })
    } else if (!this.$clAuth.finishedAuthProcess) {
      this.$clAuth.completeAuthProcess()
    }
  },
  methods: {
    openLoginPage() {
      this.$nuxt.$router.push({ path: "/login" })
    },
    openSignupPage() {
      this.$nuxt.$router.push({ path: "/signup" })
    },
  },
})
