/*
 * 本サービスでは、Role-Based Access Control（RBAC）の考え方に沿って、ユーザーをロールごとに権限管理するよう実装している。
 * ここでは、Read権限・Write権限を以下4つに分けてそれぞれ列挙型として定義している。
 *
 * 1. UniversalReadAcl（全体に関わるRead権限）
 * 2. UniversalWriteAcl（全体に関わるWrite権限）
 * 3. ParticularReadAcl（部分的に必要なRead権限）
 * 4. ParticularWriteAcl（部分的に必要なRead権限）
 *
 * 各権限に関する説明は、下で定義されている箇所にそれぞれ記載している。
 *
 * # 注意
 * 権限を追加・変更・削除または新しい箇所から参照する際は、必ず十分検討した上で行うこと。
 * Role-Based Access Control（RBAC）は十分に検討しながらメンテナンスしていけば、ロールの増加に関係なく高い変更容易性を維持しながら拡張していける。
 * 逆に適当に変更を加えていくと、非常にわかりづらい難解なものになってしまうので、１回１回の改修で常によく検討を行うのがよい。
 * また、改善の余地がないかの見直しも改修のたびに行っていくと、とても変更のしやすいDX的にも優れたものになっていくので、そういったことも意識してもらえるととてもよいと思う。
 */

/*
 * 全体に関わる閲覧（Read）権限
 * 「ユーザーアカウント、企業アカウントとして認証されていることにより閲覧できる」といった権限が該当する。
 * 3箇所以上から利用されるような権限であれば、全体に関わる権限とすることを検討するのがよい。
 * ただし、できる限りこの権限は増やさずに、必要があれば細かく ParticularReadAcl として定義していく方が、影響範囲が小さくなるため、変更容易性を担保しやすい。
 */
export enum UniversalReadAcl {
  /**
   * REQUIRE_AUTHENTICATION_RESOURCES
   * アクセスに認証が必須のリソースに対するRead権限
   */
  REQUIRE_AUTHENTICATION_RESOURCES = "REQUIRE_AUTHENTICATION_RESOURCES",

  /*
   * AUTHENTICATED_CORPORATION_RESOURCES
   * 認証されている企業のリソースに対するRead権限
   */
  AUTHENTICATED_CORPORATION_RESOURCES = "AUTHENTICATED_CORPORATION_RESOURCES",
}

/*
 * 全体に関わる更新（Write）権限
 * 「ユーザーアカウント、企業アカウントとして認証されていることにより更新できる」といった権限が該当する。
 * 3箇所以上から利用されるような権限であれば、全体に関わる権限とすることを検討するのがよい。
 * * ただし、できる限りこの権限は増やさずに、必要があれば細かく ParticularWriteAcl として定義していく方が、影響範囲が小さくなるため、変更容易性を担保しやすい。
 */
export enum UniversalWriteAcl {
  /**
   * AUTHENTICATED_USER_RESOURCES
   * 認証されたユーザーのリソースに対するWrite権限
   */
  AUTHENTICATED_USER_RESOURCES = "AUTHENTICATED_USER_RESOURCES",

  /*
   * AUTHENTICATED_CORPORATION_RESOURCES
   * 認証されている企業のリソースに対するWrite権限
   */
  AUTHENTICATED_CORPORATION_RESOURCES = "AUTHENTICATED_CORPORATION_RESOURCES",
}

/*
 * 部分的に必要な閲覧（Read）権限
 * 「他ユーザーのプロフィールのSNSを閲覧する権限」などが該当する。
 */
export enum ParticularReadAcl {
  /*
   * FULL_PARAMETER_USER_PROFILE_SEARCH
   * 全てのパラメータを利用してプロフィールを検索できる権限
   */
  FULL_PARAMETER_USER_PROFILE_SEARCH = "FULL_PARAMETER_USER_PROFILE_SEARCH",
  /*
   * PAID_PLAN_ALERT_DISMISS
   * 有料プラン訴求アラートを消す権限
   */
  PAID_PLAN_ALERT_DISMISS = "PAID_PLAN_ALERT_DISMISS",
}

/*
 * 部分的に必要な更新（Read）権限
 * 「有料企業アカウントでのみ行えるメッセージの送信権限」などが該当する。
 */
export enum ParticularWriteAcl {
  /*
   * FIRST_BASE_PROFILE_REGISTRATION
   * 最初のプロフィール基本情報登録を行うためのWrite権限
   */
  FIRST_BASE_PROFILE_REGISTRATION = "FIRST_BASE_PROFILE_REGISTRATION",

  /*
   * CORPORATION_SUBSCRIPTION_REQUEST
   * 企業の定額プランへの申し込みを行うためのWrite権限
   */
  CORPORATION_SUBSCRIPTION_REQUEST = "CORPORATION_SUBSCRIPTION_REQUEST",

  /*
   * USER_SCOUT
   * ユーザーをスカウトするためのWrite権限
   */
  USER_SCOUT = "USER_SCOUT",

  /*
   * PROJECT_CREATE
   * プロジェクトを作成するためのWrite権限
   */
  PROJECT_CREATE = "PROJECT_CREATE",

  /*
   * SEND_MESSAGE_TO_USER_AS_CORPORATION_ACCOUNT
   * 企業アカウントとしてユーザーにメッセージを送信するためのWrite権限
   */
  SEND_MESSAGE_TO_USER_AS_CORPORATION_ACCOUNT = "SEND_MESSAGE_TO_USER_AS_CORPORATION_ACCOUNT",

  /*
   * PROJECT_APPLY
   * プロジェクトに応募するためのWrite権限
   */
  PROJECT_APPLY = "PROJECT_APPLY",
}

export type Acl =
  | UniversalReadAcl
  | UniversalWriteAcl
  | ParticularReadAcl
  | ParticularWriteAcl
